import { B2BModeEnabledState } from 'types/state';
export const baseState = {
    bannerStrips: {},
    hideProducerListImages: false,
    isApp: false,
    isMobile: false,
    isTablet: false,
    modalOpen: false,
    showAddressDropdown: false,
    showApartmentNumberHelpModal: false,
    showCart: false,
    showCheckoutValidationNotification: false,
    showDeliveryDateChangeNotification: false,
    showDeliveryDatesDropdown: false,
    showNetworkErrorPage: false,
    showUserDropdown: false,
    showRegionWidget: false,
    // We need to store this enum in the UI state to recognize a situation where
    // state is restored from local storage, Customer is B2B enabled customer and they've toggled the
    // b2c version of the store. Simple boolean flag does not have enough info since we don't know if 
    // isB2BModeEnabled == false means the customer is b2c customer or not. 
    b2bModeEnabledState: B2BModeEnabledState.B2C_CUSTOMER,
    isB2BModeEnabled: false,
};
const resetState = (state) => (Object.assign(Object.assign({}, baseState), { bannerStrips: state.bannerStrips, isApp: state.isApp, isMobile: state.isMobile, isTablet: state.isTablet, showDeliveryDateChangeNotification: state.showDeliveryDateChangeNotification, showRegionWidget: state.showRegionWidget, b2bModeEnabledState: state.b2bModeEnabledState, isB2BModeEnabled: state.isB2BModeEnabled }));
export default function reducer(state = Object.assign({}, baseState), action) {
    switch (action.type) {
        case 'SET_BANNER_STRIPS':
            return Object.assign(Object.assign({}, state), { bannerStrips: Object.assign(Object.assign({}, state.bannerStrips), { [action.payload.page]: {
                        bannerStrip1: action.payload.bannerStrip1,
                        bannerStrip2: action.payload.bannerStrip2,
                    } }) });
        case 'TOGGLE_CART':
            return Object.assign(Object.assign({}, resetState(state)), { showCart: action.payload != undefined ? action.payload : !state.showCart });
        case 'TOGGLE_DELIVERY_DATE_DROPDOWN':
            return Object.assign(Object.assign({}, resetState(state)), { showDeliveryDatesDropdown: action.payload != undefined ? action.payload : !state.showDeliveryDatesDropdown });
        case 'TOGGLE_USER_DROPDOWN':
            return Object.assign(Object.assign({}, resetState(state)), { showUserDropdown: action.payload != undefined ? action.payload : !state.showUserDropdown });
        case 'TOGGLE_REGION_WIDGET':
            return Object.assign(Object.assign({}, resetState(state)), { showRegionWidget: action.payload != undefined ? action.payload : !state.showRegionWidget });
        case 'TOGGLE_ADDRESS_DROPDOWN':
            return Object.assign(Object.assign({}, resetState(state)), { showAddressDropdown: action.payload != undefined ? action.payload : !state.showAddressDropdown });
        case 'HIDE_DROPDOWNS':
            return resetState(state);
        case 'CLOSE_CHECKOUT_VALIDATION_NOTIFICATION':
            return Object.assign(Object.assign({}, state), { showCheckoutValidationNotification: false });
        case 'ORDER_VALIDATION_FAILED':
            return Object.assign(Object.assign({}, state), { showCheckoutValidationNotification: true });
        case 'TOGGLE_APARTMENT_NUMBER_HELP_MODAL':
            return Object.assign(Object.assign({}, state), { showApartmentNumberHelpModal: action.payload != undefined
                    ? action.payload
                    : !state.showApartmentNumberHelpModal });
        case 'TOGGLE_IS_TABLET':
            return Object.assign(Object.assign({}, state), { isTablet: action.payload });
        case 'TOGGLE_IS_MOBILE':
            return Object.assign(Object.assign({}, state), { isMobile: action.payload });
        case 'IS_APP':
            return Object.assign(Object.assign({}, state), { isApp: action.payload });
        case 'SHOW_DELIVERY_DATE_CHANGED_NOTIFICATION':
            return Object.assign(Object.assign({}, state), { showDeliveryDateChangeNotification: true });
        case 'CLOSE_DELIVERY_DATE_CHANGED_NOTIFICATION':
            return Object.assign(Object.assign({}, state), { showDeliveryDateChangeNotification: false });
        case 'TOGGLE_SHOW_NETWORK_ERROR_PAGE':
            return Object.assign(Object.assign({}, state), { showNetworkErrorPage: action.payload });
        case 'TOGGLE_PRODUCER_LIST_IMAGES':
            return Object.assign(Object.assign({}, state), { hideProducerListImages: action.payload });
        case 'TOGGLE_MODAL_OPEN':
            return Object.assign(Object.assign({}, state), { modalOpen: action.payload });
        case 'GET_CORE_CUSTOMER_SUCCESS':
            const b2bEnabledCustomer = !!action.payload.companyId;
            // There are 3 cases we need to consider when setting the b2bModeEnabledState and isB2BModeEnabled UI state properties
            // (1) if given customer is not associated with any company there is no other mode than b2c
            if (!b2bEnabledCustomer) {
                return Object.assign(Object.assign({}, state), { b2bModeEnabledState: B2BModeEnabledState.B2C_CUSTOMER, isB2BModeEnabled: false });
            }
            if (b2bEnabledCustomer) {
                // (2) If given customer is associated with a company and current b2bModeEnabledState is B2C_CUSTOMER
                // it means we are entirely switching to b2b version of the store so we are defaulting to b2b mode
                if (state.b2bModeEnabledState == B2BModeEnabledState.B2C_CUSTOMER) {
                    return Object.assign(Object.assign({}, state), { b2bModeEnabledState: B2BModeEnabledState.B2B_CUSTOMER_B2B_MODE, isB2BModeEnabled: true });
                    // (3) In this case we know that we have customer associated with a company and the current UI state is also one of valid B2B related states
                    // In this case we just return current state.
                    // This handles situations where b2b customer enables b2c store and refreshes the page
                }
                else {
                    return Object.assign({}, state);
                }
            }
        case 'TOGGLE_B2B_MODE':
            return Object.assign(Object.assign({}, state), { b2bModeEnabledState: action.payload ? B2BModeEnabledState.B2B_CUSTOMER_B2B_MODE : B2BModeEnabledState.B2B_CUSTOMER_B2C_MODE, isB2BModeEnabled: action.payload });
        case 'USER_LOGOUT':
            return Object.assign(Object.assign({}, state), { b2bModeEnabledState: B2BModeEnabledState.B2C_CUSTOMER, isB2BModeEnabled: false });
        default:
            return state;
    }
}
