import moment from 'moment';
import find from 'lodash/find';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { pad, capitalize } from 'utilities/text';
export function getFlatDescription(address) {
    // All fields used by this function are optional, in order to avoid printing "undefined" for the user we check existence of every field
    // before including it in the result string.
    // If there's no flat number, return an empty string.
    if (address.flatNo == null) {
        return '';
    }
    let description = '';
    if (address.floorType) {
        description += address.floorType;
    }
    if (address.floorNo != null) {
        description += pad(address.floorNo, 2, '0');
    }
    description += pad(address.flatNo, 2, '0'); // flatNo is assumed present because of the check above
    return description;
}
export function formatAddress(address) {
    return [capitalize(address.streetName), formatStreetNo(address), getFlatDescription(address)]
        .join(' ')
        .trim();
}
export function formatShortAddress(address) {
    return `${capitalize(address.streetName)}\u00A0${formatStreetNo(address)}, ${address.zip}\u00A0${capitalize(address.city)}`; // non-breaking spaces
}
export function setPrimaryAddresses(groupedAddresses, allAddresses) {
    const primary = allAddresses.filter((address) => address.isPrimary);
    primary.forEach((a) => {
        const address = find(groupedAddresses, { addressId: a.addressId }) ||
            find(groupedAddresses, {
                deliveryAddress: { deliveryPointId: a.deliveryAddress.deliveryPointId },
            });
        if (address) {
            address.isPrimary = true;
            address.validFrom = a.validFrom;
            address.validBefore = a.validBefore;
        }
    });
}
export function isValidNow(address) {
    const now = moment().startOf('day');
    if (address.validFrom && moment(address.validFrom, 'YYYY-MM-DD').isAfter(now)) {
        return false;
    }
    if (address.validBefore && !now.isBefore(moment(address.validBefore, 'YYYY-MM-DD'))) {
        return false;
    }
    return true;
}
export function findCurrentPrimaryAddress(addresses) {
    return addresses.filter((a) => a.isPrimary && isValidNow(a)).map((a) => a.deliveryAddress)[0];
}
export function findNextPrimaryAddress(addresses) {
    addresses = addresses.filter((a) => a.isPrimary);
    addresses = orderBy(addresses, 'validFrom', 'desc');
    return addresses[0];
}
export function findSameAddress(addresses, address) {
    return addresses.find((a) => a.addressId === address.addressId);
}
export function makeDeliveryAddress(address, personalInfo, transportProduct) {
    const addr = Object.assign(Object.assign({}, address), personalInfo);
    return {
        firstName: addr.firstName,
        lastName: addr.lastName,
        mobile: addr.mobile,
        deliveryPointId: addr.deliveryPointId,
        streetName: addr.streetName,
        streetNo: addr.streetNo,
        entrance: addr.entrance,
        floorNo: addr.floorNo,
        floorType: addr.floorType,
        flatNo: addr.flatNo,
        co: addr.co,
        zip: addr.zip,
        city: addr.city,
        countryCode: addr.countryCode,
        deliverySms: addr.deliverySms,
        transportProduct,
    };
}
function findBestMatch(items, value, itemFormatter) {
    if (!Array.isArray(items) || items.length === 0)
        return -1;
    if (typeof value !== 'string')
        return -1;
    const trimmedValue = value.toLowerCase().replace(/\s/g, '');
    if (trimmedValue.length === 0)
        return -1;
    const trimmedItemValues = items.map((item) => itemFormatter(item).toLowerCase().replace(/\s/g, ''));
    const indexStartsWith = trimmedItemValues.findIndex((itemValue) => itemValue.startsWith(trimmedValue));
    if (indexStartsWith >= 0)
        return indexStartsWith;
    const indexContains = trimmedItemValues.findIndex((itemValue) => itemValue.indexOf(trimmedValue) >= 0);
    if (indexContains >= 0)
        return indexContains;
    return -1;
}
export function formatStreetName(street) {
    return `${capitalize(street.streetName)}, ${street.city}`;
}
export function formatStreetNo(streetNumber) {
    return `${streetNumber.streetNo}${streetNumber.entrance ? streetNumber.entrance : ''}`;
}
export function formatFloor(floor) {
    switch (floor.floorType) {
        case 'H': return `${floor.floorNo}. etasje`;
        case 'K': return 'Kjeller';
        case 'L': return 'Loft';
        case 'U': return `${floor.floorNo}. underetasje`;
    }
}
const validFloorTypes = ['H', 'K', 'L', 'U'];
export function formatHousehold(floorType, floorNo, flatNo) {
    if (!validFloorTypes.includes(floorType)) {
        return undefined;
    }
    return `${floorType}${pad(floorNo, 2, '0')}${pad(flatNo, 2, '0')}`;
}
export function findBestStreetNameMatch(items, value) {
    return findBestMatch(items, value, formatStreetName);
}
export function findBestStreetNoMatch(items, value) {
    return findBestMatch(items, value, formatStreetNo);
}
export function findBestFloorMatch(items, value) {
    return findBestMatch(items, value, (item) => formatFloor(item));
}
export function findBestHouseholdMatch(items, floorType, floorNo, value) {
    return findBestMatch(items, value, (item) => formatHousehold(floorType, floorNo, item.flatNo));
}
export function getDeliveryPointFromState(state) {
    return get(state, 'address.active.deliveryAddress.deliveryPointId', null);
}
export function getZipCodeFromState(state) {
    return get(state, 'address.active.deliveryAddress.zip', null);
}
export function getFormattedStreetAddress(address, nonBreakingWhitespace) {
    if (!address || !address.streetName) {
        return '';
    }
    return `${capitalize(address.streetName)}${nonBreakingWhitespace ? '&nbsp;' : ' '}${address.streetNo || ''}${address.entrance || ''}`;
}
export function addressIsEqual(a, b, checkTransportProduct = true) {
    return (!!a &&
        !!b &&
        ((typeof a.co === 'undefined' && typeof b.co === 'undefined') || a.co == b.co) && // Double equals to ensure equality between null and undefined
        a.deliveryPointId === b.deliveryPointId &&
        a.firstName === b.firstName &&
        a.lastName === b.lastName &&
        (!checkTransportProduct || a.transportProduct === b.transportProduct) &&
        a.mobile === b.mobile);
}
export const splitFullName = (fullName) => {
    const parts = fullName.trim().split(' ');
    if (parts.length < 2) {
        return { firstName: fullName, lastName: '' };
    }
    else {
        const firstName = parts.slice(0, -1).join(' ');
        const lastName = parts.slice(-1).join(' ');
        return { firstName, lastName };
    }
};
export const fullNameIsValid = (fullName) => {
    const { firstName, lastName } = splitFullName(fullName);
    return (fullName.length > 0 && fullName.length <= 80 && firstName.length > 0 && lastName.length > 0);
};
