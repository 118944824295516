import { Button, ButtonRow } from 'common'
import Navigation from 'components/UI/Navigation'
import NavItem from 'components/UI/NavItem'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logout } from 'user'
import './style.scss'
import { gotoSubscriptionInfoOrOverview } from 'actions/Subscription'
import Switch from 'components/UI/Switch'
import { Modal } from '../../../common'
import { setDeliverySms } from 'actions/PersonalInfo'

class UserNavigation extends Component {
    state = {
        showStoreSwitchModal: false, // control modal visibility
        pendingB2BToggle: null, // The new value from the switch that is pending confirmation.
    }

    onB2BStoreToggleChange = (value) => {
        if (this.props.cartSize > 0) {
            this.setState({ showStoreSwitchModal: true, pendingB2BToggle: value })
        } else {
            this.applyB2BToggle(value)
        }
    }

    applyB2BToggle = (value) => {
        const { dispatch } = this.props
        const b2bModeEnabled = value

        dispatch({ type: 'TOGGLE_B2B_MODE', payload: b2bModeEnabled })
        // There is a different set of products between B2C and B2B stores so any stored product needs to be cleared.
        dispatch({ type: 'CLEAR_CART' })
        dispatch({ type: 'CLEAR_CHECKOUT_B2B_B2C_STORE_CHANGE' })
        const shouldSendSMSForOrder = !b2bModeEnabled
        // When B2B mode is enabled we want to disable SMS delivery for the order sender since they will receive it for all order lines.
        // For big orders the amount of SMS messages can be overwhelming.
        dispatch(setDeliverySms(shouldSendSMSForOrder))
    }

    // Called when the user clicks "Proceed" in the modal.
    handleProceedModal = () => {
        const { pendingB2BToggle } = this.state
        if (pendingB2BToggle !== null) {
            this.applyB2BToggle(pendingB2BToggle)
        }
        this.setState({ showStoreSwitchModal: false, pendingB2BToggle: null })
    }

    handleCancelModal = () => {
        this.setState({ showStoreSwitchModal: false, pendingB2BToggle: null })
    }

    render() {
        const { email, name, isB2BModeEnabled, isB2BModeAvailable, dispatch } = this.props
        const { showStoreSwitchModal } = this.state

        // For B2B customers for now we only want to display a relevant sub-set of dropdown options.
        return (
            <div className="user-navigation">
                {name && <h3 className="user-navigation__name">{name}</h3>}
                {email && <span className="user-navigation__email">{email}</span>}
                <Navigation type="dropdown">
                    <NavItem to="/my-page/orders">Ordrehistorikk</NavItem>
                    <NavItem to={'/my-page/addresses'}>Adresser</NavItem>
                    {!isB2BModeEnabled && (
                        <NavItem to="/my-page/payment-methods">Betalingsmiddel</NavItem>
                    )}
                    {!isB2BModeEnabled && (
                        <NavItem to="#" callback={() => dispatch(gotoSubscriptionInfoOrOverview())}>
                            Fast levering
                        </NavItem>
                    )}
                    {process.env.FAQ_URL && (
                        <NavItem href={process.env.FAQ_URL}>Ofte stilte spørsmål</NavItem>
                    )}
                </Navigation>
                {isB2BModeAvailable && (
                    <div className="user-navigation__switch-bar">
                        <span className="user-navigation__switch-label">B2B store</span>
                        <Switch on={isB2BModeEnabled} onChange={this.onB2BStoreToggleChange} />
                    </div>
                )}
                <ButtonRow display="center">
                    <Button
                        type="action"
                        style="ghost"
                        size="small"
                        onClick={() => dispatch(logout())}
                    >
                        Logg ut
                    </Button>
                </ButtonRow>
                {showStoreSwitchModal && (
                    <Modal
                        onClose={this.handleCancelModal}
                        className="user-navigation__b2b-modal"
                        labelId="b2bModalLabel"
                    >
                        <h2 id="b2bModalLabel">Non-empty cart detected</h2>
                        <p>
                            You have non-empty cart. Switching between B2B/B2C store will empty your
                            current cart selection.
                        </p>
                        <ButtonRow display="center">
                            <Button type="action" style="ghost" onClick={this.handleCancelModal}>
                                Cancel
                            </Button>
                            <Button type="action" style="primary" onClick={this.handleProceedModal}>
                                Proceed
                            </Button>
                        </ButtonRow>
                    </Modal>
                )}
            </div>
        )
    }
}

UserNavigation.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    isB2BModeEnabled: PropTypes.bool,
    isB2BModeAvailable: PropTypes.bool,
    cartSize: PropTypes.number,
    dispatch: PropTypes.func,
}

const mapStateToProps = ({ user, ui, personalInfo, cart }) => {
    let email = user.jwtData.spidEmail
    const name = `${user.spidData.givenName} ${user.spidData.familyName}`
    const isB2BModeEnabled = ui.isB2BModeEnabled
    const isB2BModeAvailable = personalInfo.b2bCustomer
    const cartSize = cart.products.length
    return { email, name, isB2BModeEnabled, isB2BModeAvailable, cartSize }
}

export default connect(mapStateToProps)(UserNavigation)
