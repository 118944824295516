import './DropDown.scss';
import React, { useRef } from 'react';
import Icon from 'components/UI/Icon';
import Spinner from 'components/UI/Spinner';
import classNames from 'classnames';
import { useOnClickOutside } from 'common';
import ReactDOM from 'react-dom';
const chevronIcon = require('img/chevron.svg');
function DropDown(props) {
    const classes = classNames({
        [`${props.className}`]: props.className,
        'drop-down': true,
        'drop-down--expanded': props.expanded,
        'drop-down--scrollable': props.scrollable,
        'drop-down--disabled': props.disabled,
    });
    const buttonClasses = classNames({
        'drop-down__button': true,
        'drop-down__button--invalid': props.invalid,
    });
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, (event) => {
        if (props.expanded && buttonRef.current.contains(event.target)) {
            return;
        }
        // We want the DropDown to close itself when users click outside the DropDown area. But this also means
        // that any kind of click to modal elements displayed will be captured and won't be received by it. 
        // This specific exclusion is for Modal that is displayed when user wants to switch between b2b and b2c version of the store
        // and have non-empty card. 
        // See UserNavigation component for mode details.
        if (event.target instanceof Element && event.target.closest('.user-navigation__b2b-modal')) {
            return;
        }
        if (!props.disableCloseOnClickOutside && props.expanded) {
            props.onClose();
        }
    });
    const dropDownContent = () => {
        if (props.portalTo) {
            return ReactDOM.createPortal(React.createElement("div", { className: "drop-down__content", ref: dropdownRef }, props.children), props.portalTo);
        }
        else {
            return (React.createElement("div", { className: "drop-down__content", ref: dropdownRef }, props.children));
        }
    };
    return (React.createElement("div", { className: classes },
        React.createElement("button", { ref: buttonRef, type: "button", className: buttonClasses, onClick: props.onClick, disabled: props.disabled, "aria-label": props.ariaLabel || props.title },
            props.icon && props.icon,
            !props.loading && props.title && (React.createElement("span", { className: "drop-down__title" }, props.title)),
            props.loading && React.createElement(Spinner, { color: '0,0,0' }),
            !props.disabled && (React.createElement(Icon, { className: "drop-down__icon", svg: chevronIcon, size: "xsmall" }))),
        React.createElement("div", { className: "drop-down__body" }, props.expanded && dropDownContent())));
}
export default DropDown;
