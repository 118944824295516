import { PlaceOrderButton, RelatedProductsInterrupt, resetOrderAttempts, setShowRelatedProductsInterrupt, } from 'checkout';
import { Button, ButtonRow, Card, PageWithCards } from 'common';
import { CartContent, CartTotal, FreeFreightProgress } from 'cart';
import { push } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutValidationContext } from '../checkoutValidationContext';
import CheckoutCouponCode from '../components/CheckoutCouponCode';
import CheckoutTerms from '../components/CheckoutTerms/CheckoutTerms';
import DeliveryDateCard from '../components/DeliveryDateCard/DeliveryDateCard';
import EmailCard from '../components/EmailCard/EmailCard';
import Errors from '../components/Errors/Errors';
import GiftMessageCard from '../components/GiftMessageCard/GiftMessageCard';
import PaymentCard from '../components/PaymentCard/PaymentCard';
import RecipientCard from '../components/RecipientCard/RecipientCard';
import SenderCard from '../components/SenderCard/SenderCard';
import NewCheckoutPageSubscription from './NewCheckoutPageSubscription';
import './NewCheckoutPage.scss';
import NewCheckoutPageB2B from './NewCheckoutPageB2B';
function NewCheckoutPage() {
    const dispatch = useDispatch();
    const checkoutMode = useSelector((state) => state.checkout.checkoutMode);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const isB2BModeEnabled = useSelector((state) => state.ui.isB2BModeEnabled);
    const userIsLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const cartTotal = useSelector((state) => state.cart.total);
    const isMobile = useSelector((state) => state.ui.isMobile);
    const showRelatedProductsInterrupt = useSelector((state) => state.newCheckout.showRelatedProductsInterrupt);
    const isSubscription = checkoutMode === 'SUBSCRIPTION_ORDER';
    const [interruptHasJustBeenDismissed, setInterruptHasJustBeenDismissed] = useState(false);
    // Used to make sure we only scroll to the first invalid field
    const shouldScrollRef = useRef(false);
    const orderAttempts = useSelector((state) => state.newCheckout.orderAttempts);
    useEffect(() => {
        shouldScrollRef.current = true;
    }, [orderAttempts]);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(resetOrderAttempts());
    }, [dispatch]);
    useEffect(() => {
        if (!showRelatedProductsInterrupt) {
            window.scrollTo(0, 0);
        }
    }, [showRelatedProductsInterrupt]);
    const showPaymentMethodTypeSelector = cartTotal > 0;
    if (isSubscription) {
        return React.createElement(NewCheckoutPageSubscription, null);
    }
    else if (isB2BModeEnabled) {
        return React.createElement(NewCheckoutPageB2B, null);
    }
    else {
        return (React.createElement(CheckoutValidationContext.Provider, { value: shouldScrollRef },
            React.createElement(PageWithCards, { header: React.createElement(Errors, null) },
                showRelatedProductsInterrupt && isMobile && (React.createElement(ButtonRow, { display: "stretch", className: "checkout-related-products-interrupt-buttons" },
                    React.createElement(Button, { type: "action", style: "ghost", onClick: () => dispatch(push('/')) }, "Handle mer"),
                    React.createElement(Button, { type: "action", style: "primary", onClick: () => {
                            dispatch(setShowRelatedProductsInterrupt(false));
                            setInterruptHasJustBeenDismissed(true);
                        } }, "Fortsett til kassen"))),
                React.createElement(Card, { title: "Din ordre", accordion: userIsLoggedIn &&
                        !showRelatedProductsInterrupt &&
                        interruptHasJustBeenDismissed }, !isSubscription && (React.createElement("div", { className: 'checkout-page__order-summary' },
                    React.createElement(CartContent, null),
                    React.createElement(CartTotal, null),
                    React.createElement(FreeFreightProgress, null),
                    orderType !== 'group' &&
                        (!showRelatedProductsInterrupt || userIsLoggedIn) ? (React.createElement(CheckoutCouponCode, null)) : null))),
                showRelatedProductsInterrupt ? (React.createElement(RelatedProductsInterrupt, { onProceed: () => {
                        dispatch(setShowRelatedProductsInterrupt(false));
                        setInterruptHasJustBeenDismissed(true);
                    }, onCancel: () => dispatch(push('/')) })) : (React.createElement(React.Fragment, null,
                    React.createElement(RecipientCard, null),
                    orderType !== 'single' && React.createElement(GiftMessageCard, null),
                    orderType !== 'single' && React.createElement(SenderCard, null),
                    orderType === 'single' && React.createElement(EmailCard, null),
                    React.createElement(DeliveryDateCard, null),
                    showPaymentMethodTypeSelector && React.createElement(PaymentCard, null),
                    React.createElement(ButtonRow, { display: "center" },
                        React.createElement(PlaceOrderButton, null)),
                    React.createElement(CheckoutTerms, null))))));
    }
}
export default NewCheckoutPage;
